@tailwind base;
@tailwind components;
@tailwind utilities;

.hero {
  background-image: url("/public/herobg.jpg");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
}

.course-hero {
  background-image: url("/public/course_bg.png");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
}
